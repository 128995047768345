/* Navigation Bar Styling */
.navigation {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 15px; /* Add padding for spacing */
  background-color: #333; /* Dark background for contrast */
  border-bottom: 3px solid #ff0019; /* Optional border for separation */
  position: sticky; /* Sticks to the top of the page */
  top: 10; /* Ensures it stays at the top */
  z-index: 1000; /* Ensures it stays above other elements */
}

/* Navigation Links Styling */
.nav-link {
  text-decoration: none;
  color: white;
  font-size: 2em; /* Larger font for emphasis */
  font-weight: bold; /* Makes the letters fatter */
  padding: 20px 30px; /* More padding for a spacious look */
  border: 2px solid transparent; /* Default transparent border */
  border-radius: 8px; /* Rounded corners for links */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.nav-link:hover {
  background-color: white; /* Invert colors on hover */
  color: #FF6347; /* Complementary color for hover text */
  border-color: #a32323; /* Adds a visible border on hover */
}

/* Optional: Adjust gap and padding for smaller screens */
@media (max-width: 768px) {
  .navigation {
    flex-wrap: wrap; /* Stack items if the screen is too narrow */
    padding: 10px; /* Reduce padding on smaller screens */
  }

  .nav-link {
    font-size: 1.5em; /* Slightly smaller font for mobile */
    padding: 15px 20px; /* Adjust padding */
  }
}

  