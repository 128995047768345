/* General Background Styling */
#backgroundVideo {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -100;
    background-size: cover;
  }
  
  /* General Styling */
  .App {
    text-align: center;
    color: white;
  }
  
  .App-logo {
    height: 10vmin;
    pointer-events: none;
  }
  
  .nft-page-header {
    font-size: 2.5rem; /* Adjust this value to match other pages */
    font-family: 'MyGraffitiFont', sans-serif; /* Optional: Same font as homepage */
    color: #FF6347; /* Consistent color */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
  }
  
  /* NFT Gallery Grid */
  .nft-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
    margin: 50px auto;
    max-width: 90%;
    background-color: rgba(18, 18, 25, 0.95); /* Dark semi-transparent background */
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }
  
  /* Individual NFT Card Styling */
  .nft-card {
    background-color: #1c1c1d;
    padding: 15px;
    border-radius: 10px;
    text-align: center;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .nft-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
  }
  
  /* NFT Image */
  .nft-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
    border: 2px solid #FF6347; /* Highlighted border */
  }
  
  /* NFT Name */
  .nft-name-box {
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  /* Action Button for Staking */
  .nft-action-button {
    font-size: 1.1rem;
    padding: 10px 20px;
    background-color: #1363cc; /* Button color */
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.2s ease;
  }
  
  .nft-action-button:hover {
    background-color: #0d0daf; /* Darker hover color */
    transform: scale(1.05);
  }
  
  /* Rewards Section */
  .rewards-section {
    background-color: rgba(18, 18, 25, 0.95);
    padding: 20px;
    margin: 20px auto;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .rewards-text {
    font-size: 1.5rem;
    font-weight: bold;
    color: #00FF00; /* Bright green for rewards */
  }
  
  .rewards-button {
    margin-top: 10px;
    font-size: 1.1rem;
    padding: 10px 20px;
    background-color: #FF6347; /* Red button */
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.2s ease;
  }
  
  .rewards-button:hover {
    background-color: #FF4500; /* Darker red for hover */
    transform: scale(1.05);
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .nft-page-header {
      font-size: 2.5rem;
    }
  
    .nft-gallery {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
  
    .nft-card {
      padding: 10px;
    }
  }
  