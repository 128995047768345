.chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    color: white;
  }
  
  .chart-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .iframe-container {
    width: 90%;
    max-width: 1200px;
    height: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    overflow: hidden;
    border: 2px solid #4a148c;
  }
  