#backgroundVideo {
  position: fixed;
  top: 0;
  left: 0;
  right: 0; 
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;  
  object-position: top;  
  z-index: -100;
  background-size: cover;
}

.App {
  text-align: center;
  color: white;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.content-bubble {
  background-color: #121219; /* Light grey background */
  padding: 10px; /* Spacious padding around the content */
  margin: 200px 0; /* Vertical spacing between bubbles */
  border-radius: 10px; /* Rounded corners for the bubble */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  text-align: center; /* Align text to the left inside the bubble */
  width: 90%; /* Manageable width */
  max-width: 400px; /* Maximum width */
  box-sizing: border-box; /* Include padding and border in the width */
}

.content-bubble2 {
  background-color: #22222e; /* Light grey background */
  padding: 10px; /* Spacious padding around the content */
  margin: 200px 0; /* Vertical spacing between bubbles */
  border-radius: 10px; /* Rounded corners for the bubble */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  display: inline-block; /* Allows the bubble to shrink/grow with content */
  text-align: center; /* Align text to the left inside the bubble */
  max-width: 800px; /* Maximum width */
  box-sizing: border-box; /* Include padding and border in the width */
}

.content-bubble3 {
  background-color: #1c1c1d; /* Light grey background */
  padding: 10px; /* Spacious padding around the content */
  margin: 200px 0; /* Vertical spacing between bubbles */
  border-radius: 10px; /* Rounded corners for the bubble */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  display: inline-block; /* Allows the bubble to shrink/grow with content */
  text-align: center; /* Align text to the left inside the bubble */
  max-width: 800px; /* Maximum width */
  box-sizing: border-box; /* Include padding and border in the width */
}

.content-bubble a {
  color: #0d6efd; /* Bootstrap blue or any preferred blue color */
  text-decoration: none; /* Remove underlines */
  font-weight: bold; /* Ensure consistency */
}

.content-bubble a:hover {
  text-decoration: underline; /* Optional hover effect */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: transparent;
  width: 100%; /* Ensure the header spans the full width */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px; /* Proper padding property */
  box-sizing: border-box;
}

.App-link {
  color: #61dafb;
}

.dance-image {
  width: 0;
  opacity: 0;
  animation: appear 0.5s ease forwards,
             spin 2s 0.5s ease forwards,
             flip 0.5s 2.5s ease-in-out forwards,
             rotateIn 1s 3s ease-out forwards,
             dance 2s 4s infinite ease-in-out;
}

@keyframes appear {
  from {
    width: 0;
    opacity: 0;
  }
  to {
    width: 200px; /* Target size of the image */
    opacity: 1;
  }
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes flip {
  from { transform: rotateY(0); }
  to { transform: rotateY(360deg); }
}

@keyframes rotateIn {
  from { transform: rotateX(-360deg); }
  to { transform: rotateX(0deg); }
}

@keyframes dance {
  0%, 100% { transform: translateY(0); }
  25%, 75% { transform: translateY(-20px); }
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

@keyframes social-icon-rotateIn {
  from { transform: rotateY(-360deg); }
  to { transform: rotateY(0deg); }
}

.liquidity-info {
  animation: blink 1s linear infinite;
  background-color: #8e44ad; /* Example background color */
  color: #e5e4e2; /* Example text color */
  padding: 10px;
  border-radius: 5px;
}

.large-icon {
  font-size: 2em; /* Double the size of the icon */
  margin-right: 20px;
  animation: social-icon-rotateIn 4s linear infinite;
}

.social-link {
  margin-right: 10px;
}

.social-link i {
  animation: social-icon-spin 4s linear infinite;
}

.social-link .fa-twitter {
  color: #1DA1F2; /* Twitter brand color */
}

.social-link .fa-discord-plane {
  color: #dce2e5; /* Discord brand color */
}

.social-link .fa-tiktok {
  color: #69C9D0; /* Telegram brand color */
}

.social-link .fa-youtube {
  color: #FF0000; /* Telegram brand color */
}

.link-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px; /* Adjust for rounded corners */
  color: #fff;
  text-decoration: none;
  width: 200px; /* Adjust width as necessary */
  height: 100px; /* Adjust height as necessary */
  font-weight: bold;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.3s, transform 0.3s;
  background-image: linear-gradient(145deg, #ffffff40 10%, #00000020 90%);
  overflow: hidden; /* Ensures no spillover of content */
}

.dexscreener {
  background-color: #4CAF50; /* Green */
  color: #FFFFFF; /* White */
}

.dexscreener:hover {
  background-color: #FFC300;
}

.raydium {
  background-color: #1445ca; /* Gold */
  color: #bdc3c7; /* Dark Green */
  border: 1px solid #FFD700; /* Gold border */
}

.raydium:hover {
  background-color: #44ad5b;
}

.solscan {
  background-color: #21BF96;
}

.solscan:hover {
  background-color: #e5e4e2;
}

.link:active {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transform: translateY(3px);
}

.link:hover {
  transform: translateY(-1px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.4);
}

.link span {
  display: block;
  text-align: center;
}

.no-bullets {
  list-style-type: none; /* Remove default bullets */
  padding-left: 0; /* Remove default padding */
}

.sophisticated-list {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Sophisticated font */
  color: white; /* Elegant text color */
}

.sophisticated-list li {
  padding: 10px 0; /* Spacing between items */
  border-bottom: 1px solid #ccc; /* Adds a subtle line between items */
  position: relative; /* Needed for absolute positioning of the pseudo-element */
}

.sophisticated-list li:before {
  content: '✦'; /* Custom bullet symbol */
  color: #24292f; /* Bullet color */
  font-weight: bold; /* Make the bullet symbol bolder */
  position: absolute; /* Positioning before the text */
  left: -20px; /* Adjust as necessary */
}

.sophisticated-list li:last-child {
  border-bottom: none; /* Removes border from the last item */
}

/* Stylesheet: styles.css */
.flash-text {
  animation: flashText 0.3s linear infinite alternate;
  color: red; /* Set the color to red to simulate a siren */
}

@keyframes flashText {
  from { opacity: 1; }
  to { opacity: 0; }
}

/* Fire animation CSS */
@keyframes fireGlow {
  0% { 
    box-shadow: 0 0 10px 5px orange, 0 0 20px 10px red, 0 0 30px 15px yellow;
  }
  50% { 
    box-shadow: 0 0 15px 7px red, 0 0 25px 12px orange, 0 0 35px 17px yellow;
  }
  100% { 
    box-shadow: 0 0 10px 5px orange, 0 0 20px 10px red, 0 0 30px 15px yellow;
  }
}

.fire-effect {
  display: inline-block;
  animation: fireGlow 2s ease-in-out infinite;
  position: relative;
}

/* Stylesheet: styles.css */
.burn-transaction-link {
  padding: 10px 20px;
  background-color: #f44336; /* Red color for urgency */
  color: white;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.2s;
}

.burn-transaction-link:hover {
  background-color: #d32f2f; /* A darker shade of red on hover */
  transform: translateY(-2px); /* Slight raise effect on hover */
  box-shadow: 0 4px 8px rgba(0,0,0,0.3); /* Shadow for depth */
}

.burn-transaction-link:active {
  transform: translateY(1px); /* Push effect when clicked */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.wallet-info {
  background-color: rgba(0, 0, 0, 0.8);  /* Dark transparent background */
  padding: 15px;
  border-radius: 10px;
  color: #00ff00;  /* Bright green text color */
  text-align: center;
  width: 80%;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.wallet-text {
  font-size: 18px;
  font-weight: bold;
}

.App-header .wallet-info Button {
  background-color: #ff6347;  /* Disconnect button color */
  color: white;
  border-radius: 5px;
  font-size: 16px;
}

.nft-name-box {
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  width: fit-content;
  margin: 0 auto;
}

.buy-walt-button {
  font-size: 1.5rem; /* Increases the font size */
  padding: 1rem 2rem; /* Adds padding for a larger button */
  background-color: #1363cc; /* Custom button color */
  color: #fff; /* Text color */
  border: none; /* Removes default border */
  border-radius: 8px; /* Rounds the button corners */
  cursor: pointer;
  transition: transform 0.2s ease; /* Smooth hover effect */
}

.buy-walt-button:hover {
  transform: scale(1.05); /* Slightly enlarges button on hover */
  background-color: #0d0daf; /* Darker shade for hover */
}

@font-face {
  font-family: 'MyGraffitiFont'; /* Custom name for your font */
  src: url('../public/Fonts/Graffiti.ttf') format('truetype'); /* Adjust path if necessary */
  font-weight: normal;
  font-style: normal;
}

.graffiti-title {
  font-family: 'MyGraffitiFont', sans-serif; /* Fallback to sans-serif */
  font-size: 5rem; /* Adjust size */
  color: #FF6347;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 5.5);
}

.tinyman-iframe-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.tinyman-iframe-container iframe {
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
